<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoice === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>

    <b-row
      v-if="invoice"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >

        <b-card
          v-for="invoice in invoices"
          :id="invoice.id"
          :key="invoice.id"
          no-body
          class="invoice-preview-card page-break"
        >
          <!-- Header -->
          <b-row
            style="border: 1px solid black; border-radius: 18px; padding: 10px"
          >
            <!-- Header: Left Content -->

            <b-col
              cols="12"
              md="12"
              style="color: black; font-size: 18px; text-align: center"
            >
              <img
                src="@/assets/images/logo/logo.png"
                width="150px"
                height="auto"
              >
              <div
                style="color: black; font-size: 40px"
                class="font-weight-bolder"
              >
                GROUPES SERVICES RAPIDES
                <div style="font-size: 28px">
                  Vente tous genre de cléfs brut, machines et accessoires
                </div>
              </div>

              <b-card-text
                style="font-size: 20px"
                class="font-weight-bold mt-1"
              >
                <div>RUE EL KOUNAITRA 1-dar Chaabene El Fehri</div>
                <div class="mt-1">
                  Tel: +216 97 607 740 , Email: gsr-cle1@hotmail.fr
                </div>

                <div class="mt-1 mb-1">
                  MF : 1082793 F/A/M/000 | C.D 837979P
                </div>
              </b-card-text>
            </b-col>
          </b-row>
          <div class="mt-1">
            <b-row style="color: black">
              <b-col
                cols="8"
                md="8"
                sm="12"
              >
                <h3
                  class="font-weight-bolder"
                  style="
                    color: black;
                    font-size: 28px;
                    border: 1px solid black;
                    padding: 10px;
                    text-align: center;
                  "
                >
                  FACTURE
                </h3>
                <div class="mt-1 d-flex">
                  <table style="border: 1px solid black">
                    <tr
                      style="
                        color: black;
                        font-size: 20px;
                        border: 1px solid black;
                      "
                    >
                      <th
                        style="text-align: center"
                        class="font-weight-bolder"
                      >
                        N° du pièce
                      </th>
                    </tr>
                    <tr
                      style="
                        color: black;
                        font-size: 20px;
                        border: 1px solid black;
                      "
                    >
                      <td
                        v-if="invoice"
                        style="text-align: center"
                        class="font-weight-bolder"
                      >
                        {{ invoice.number }}
                      </td>
                    </tr>
                  </table>
                  <table style="border: 1px solid black; margin-left: 10px">
                    <tr
                      style="
                        color: black;
                        font-size: 20px;
                        border: 1px solid black;
                      "
                    >
                      <th
                        style="text-align: center"
                        class="font-weight-bolder"
                      >
                        Date
                      </th>
                    </tr>
                    <tr
                      style="
                        color: black;
                        font-size: 20px;
                        border: 1px solid black;
                      "
                    >
                      <td
                        v-if="invoice"
                        style="text-align: center"
                        class="font-weight-bolder"
                      >
                        {{ format(invoice.date) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </b-col>
              <b-col
                v-if="invoice.customer"
                cols="4"
                md="4"
                sm="12"
                style="
                  border: 1px solid black;
                  border-radius: 18px;
                  font-size: 14px;
                  padding: 10px;
                  background-color: #eeede9;
                "
              >
                <!-- Header: Left Content -->
                <div style="color: black; font-size: 20px">
                  <h4
                    style="color: black; font-size: 20px"
                    class="mb-1 font-weight-bolder"
                  >
                    {{ invoice.customer.company_name }}
                  </h4>
                  <span class="mb-0 mt-1 font-weight-bold">
                    {{ invoice.customer.address }}
                  </span>
                  <b-card-text
                    v-if="invoice.customer.tax_identification_number"
                    class="mb-0 mt-1 font-weight-bold"
                  >
                    MF : {{ invoice.customer.tax_identification_number }}
                  </b-card-text>

                </div>

                <!-- Header: Right Content -->
                <!-- Header: Right Content -->
              </b-col>
            </b-row>
          </div>
          <!-- Invoice Description: Table -->
          <table class="container-products mt-2">
            <b-table-lite
              :bordered="true"
              responsive
              :items="invoice.p"
              :small="true"
              :fields="[
                'N°',
                'Réf',
                'Désignation',
                'Qté',
                'Prix U.H.T',
                'Total H.T',
                'Taux TVA',
              ]"
              class="ta font-weight-bold mt-1"
            >
              <template #cell(taskDescription)="data">
                <b-card-text
                  class="font-weight-bold mb-25"
                  style="font-size: 25px; border: 2px solid black !important"
                >
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </table>
          <!-- Invoice Description: Total -->

          <div class="page-break">
            <b-row class="card-body mt-2">
              <b-col
                cols="6"
                md="6"
                xl="6"
                sm="12"
                style="
                  border: 1px solid black;
                  padding: 10px;
                  border-radius: 18px;
                "
              >
                <span
                  class="font-weight-bolder mt-1"
                  style="
                    color: black;
                    border-bottom: 1px solid black;
                    font-size: 20px;
                  "
                > Arrêté la présente facture à la somme de : </span>
                <br>
                <br>
                <span
                  class="font-weight-bold mt-1"
                  style="color: black; font-size: 20px"
                >
                  {{ invoice.lettre }}
                </span>
                <!-- <div v-html="order.note" /> -->
              </b-col>
              <b-col cols="1" />
              <b-col
                cols="5"
                md="5"
                xl="5"
                sm="12"
                class="p-0"
                style="
                  border: 1px solid black;
                  padding: 10px;
                  border-radius: 18px;
                "
              >
                <table class="border-pricing m-1">
                  <tbody>
                    <tr class="trborder">
                      <th
                        scope="row"
                        style="color: black; font-size: 20px"
                        class="border-pricing"
                      >
                        Total Hors TVA :
                      </th>
                      <td
                        style="color: black; font-size: 20px"
                        class="font-weight-bold border-pricing"
                      >
                        {{ invoice.price_ht }} TND
                      </td>
                    </tr>
                    <tr
                      class="trborder"
                      style="color: black; font-size: 20px"
                    >
                      <th
                        scope="row"
                        class="border-pricing"
                      >
                        Total TVA :
                      </th>
                      <td class="font-weight-bold border-pricing">
                        {{ invoice.tax }} TND
                      </td>
                    </tr>
                    <tr
                      class="trborder"
                      style="color: black; font-size: 20px"
                    >
                      <th
                        scope="row"
                        class="border-pricing"
                      >
                        Timbre :
                      </th>
                      <td class="font-weight-bold border-pricing">
                        {{ invoice.timbre_fiscale }} TND
                      </td>
                    </tr>
                    <tr style="color: black; font-size: 20px">
                      <th
                        scope="row"
                        class="border-pricing"
                      >
                        Net à payer :
                      </th>
                      <td class="font-weight-bold border-pricing">
                        {{ (Number(invoice.price_ttc)).toFixed(3) }} TND
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row class="card-body text-center">
              <b-col
                cols="6"
                md="6"
                xl="6"
                sm="12"
              >
                <h5 style="color: black; font-size: 16px">
                  Signature
                </h5>
              </b-col>
            </b-row>
          </div>
        </b-card>

      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card v-if="!isLoading">
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axiosIns from '@/libs/axios'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { NumberToLetter } from 'convertir-nombre-lettre'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  props: ['all'],
  data() {
    return {
      invoices: [],
      p: [],
      invoice: [],
      lettre: '',
      isLoading: false,
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getInvoices()
    // this.getProducts()
  },

  methods: {
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    async getInvoices() {
      this.isLoading = true
      for (let k = 0; k < this.all.length; k += 1) {
        // eslint-disable-next-line no-await-in-loop
        await axiosIns
          .get(`/invoices/details/${this.all[k]}/`)
          .then(response => {
            this.invoice = response.data
          })
        if (this.invoice != null) {
          const sommeString = this.invoice.price_ttc.toString()
          if (sommeString.includes('.000') === true) {
            this.invoice.lettre = `${NumberToLetter(
              this.invoice.price_ttc,
            )} Dinars `
          } else {
            const [dinar, milim] = this.invoice.price_ttc.split('.')
            this.invoice.lettre = `${NumberToLetter(
              dinar,
            )} Dinars , ${NumberToLetter(milim)} `
          }
          // eslint-disable-next-line no-await-in-loop
          const { data } = await axiosIns.post('/invoices-products/details/', {
            invoice: this.invoice.id,
          })
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < data.length; i++) {
            this.p.push({
              Réf: data[i].reference,
              Désignation: data[i].label,
              Qté: data[i].quantity,
              'Prix U.H.T': data[i].price_ht,
              'Total H.T': data[i].total_ht,
              'Taux TVA': data[i].vat,
              'Prix U.TTC': data[i].price_ttc,
              'Total TTC': data[i].total_ttc,
              'N°': i + 1,
            })
            this.invoice.p = this.p
          }
          this.p = []
        }
        this.invoices.push(this.invoice)
        this.isLoading = false
      }
    },

    // eslint-disable-next-line camelcase
    addnewitem(
      nb,
      reference,
      label,
      quantity,
      price_ht,
      total_ht,
      tax,
      price_ttc,
      total_ttc,
    ) {
      this.p.push({
        Réf: reference,
        Désignation: label,
        Qté: quantity,
        'Prix U.H.T': price_ht,
        'Total H.T': total_ht,
        'Taux TVA': tax,
        'Prix U.TTC': price_ttc,
        'Total TTC': total_ttc,
        'N°': nb,
      })
    },
    computed: {
      pageHeight() {
        // Calculate the page height based on the paper size and margins.
        return 1100 // For example, assuming A4 paper with 1 inch margins.
      },
    },
  },
  setup() {
    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,
    }
  },
}
</script>


<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
.trborder {
  border-bottom: 0px solid black;
}
footer {
  bottom: 0;
  height: auto; /* Footer height */
}
.container-products{
  min-height: 500px;
  border: 1px solid black;
}
table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor {
  background-color: #208a9d;
}
.contact {
  background-color: #208a9d;
  padding: 20px;
  border-radius: 25px;
  -webkit-print-color-adjust: exact;
}
.page-break {
  page-break-inside: avoid;
}
@media print {
  @page {
    size: A4;
  }
  .page-break {
    page-break-inside: avoid;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor {
    background-color: #208a9d;
    -webkit-print-color-adjust: exact;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .border-pricing {
    text-align: left;
    border: 0px solid black;
  }
}
</style>
